body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

html, body, #root, .App {
    height: 100%;
}
#root {
    display: flex;
    flex-direction: column;
}

:root {
    --pink: #ecb2ba;
    --pink-dark: #c6939a;
    --tape-gray: #eeeae5;
    --tape-edge-gray: #d5d4c7;
    --transparent: rgba(255, 255, 255, 0);
}

.blue {
    --paper-color: #636c7a;
    --paper-dark: #636b80;
}

.tape-section {
    position: absolute;
    width: 100%;
}

.top-tape {
    position: absolute;
    height: 6vmin;
    top: -5px;
    width: 120%;
    background-color: var(--tape-gray);
    border-right: 1px dotted var(--tape-edge-gray);
    border-left: 1px dotted var(--tape-edge-gray);
    opacity: 0.5;
}

.tape-section:first-of-type {
    top: 0;
}

.tape-section:last-of-type {
    bottom: 0;
}

.tape-section::before,
.tape-section::after {
    content: "";
    width: 16vmin;
    height: 6vmin;
    position: absolute;
    background-color: var(--tape-gray);
    opacity: 0.5;
    border-right: 1px dotted var(--tape-edge-gray);
    border-left: 1px dotted var(--tape-edge-gray);
}

.tape-section:last-of-type::after {
    transform: rotate(-45deg);
    right: -5vmin;
    top: -4vmin;
}

.tape-section:first-of-type::before {
    transform: rotate(-45deg);
    left: -5vmin;
}

.tape-section:first-of-type::after {
    transform: rotate(45deg);
    right: -5vmin;
    top: 0;
}

.tape-section:last-of-type::before {
    transform: rotate(45deg);
    left: -5vmin;
    bottom: 0;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
